<template>
  <ion-app>
    <ion-split-pane content-id="main-content"> 
      <menu-mobile :items="menuItems" @avatarClick="selectAvatarMenu()"></menu-mobile>
      <ion-router-outlet id="main-content"></ion-router-outlet>
      <input type="file" ref="avatarInput" accept="image/jpeg, image/gif, image/png" hidden @change="onAvatarSelected($event)">
    </ion-split-pane>
      <ion-modal css-class="network-error-modal__outer" :is-open="networkError" :can-dismiss="false">
        <network-error-modal></network-error-modal>
      </ion-modal>
      <ion-modal css-class="avatar-editor-modal__outer" :is-open="avatarEditorModalOpened" @didDismiss="avatarEditorModalOpened = false;" :can-dismiss="true">
        <avatar-editor-modal :url="newAvatar"></avatar-editor-modal>
      </ion-modal>
  </ion-app>
</template>

<script>
import { 
    IonApp, 
    IonSplitPane,
    IonRouterOutlet,
    IonModal, 
    actionSheetController,
    alertController,
    isPlatform,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { 
    home,
    time,
    newspaper,
    briefcase,
    earth,
    informationCircle,
    call,
    wallet,
    carSport,
    people,
} from 'ionicons/icons';
import MenuMobile from './views/shared/MenuMobile.vue';
import NetworkErrorModal from './views/shared/NetworkErrorModal.vue'
import useAPI from './api/useAPI.js';
import AvatarEditorModal from './views/Cabinet/AvatarEditorModal.vue';
import { ImagePicker } from '@awesome-cordova-plugins/image-picker';
import { Camera } from '@awesome-cordova-plugins/camera';
import usePreorder from './api/usePreorder.js';
import pushNotifications from './api/push.js';

/**
 * Интервал обновления геолокации, мс
 * @type {Number}
 */
const GEOLOCATION_INTERVAL = 10000;

/**
 * Интервал обновления незавершенных заказов, мс
 * @type {Number}
 */
// const INCOMPLETED_ORDERS_CHECK_INTERVAL = 10000;

export default defineComponent({
    name: 'App',
    components: {
        IonApp, 
        IonSplitPane,
        IonRouterOutlet, 
        MenuMobile,
        NetworkErrorModal,
        IonModal,
        AvatarEditorModal,
    },
    setup() {
        const api = useAPI();

        const router = useRouter();
        const route = useRoute();
        const { preorder } = usePreorder();
        
        return { 
            route, 
            router, 
            networkError: api.networkError,
            api,
            preorder,
        };
    },
    data() {
        return {
            newAvatar: null,
            avatarEditorModalOpened: false,
            currentPosition: {
                lat: 0,
                lon: 0,
            },
            me: null,
        };
    },
    async mounted() {
        const response = await this.api.method();
        this.api.updateData(response);
        this.checkInstantOrders();
        const yandexMapsScript = document.createElement('script');
        let yandexMapsScriptURL = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';
        if (this.api.data.yandexMapsApiKey) {
            yandexMapsScriptURL += '&apikey=' + this.api.data.yandexMapsApiKey;
        }
        if (this.api.data.yandexMapsSuggestApiKey) {
            yandexMapsScriptURL += '&suggest_apikey=' + this.api.data.yandexMapsSuggestApiKey;
        }
        yandexMapsScript.setAttribute('src', yandexMapsScriptURL);
        document.head.appendChild(yandexMapsScript);

        while (!window.ymaps) {
            await new Promise(resolve => window.setTimeout(resolve, 100));
        }
        window.ymaps.ready(() => {
            window.setTimeout(() => {
                this.getGeolocation();
            }, 0); // Задержка, чтобы успел смонтироваться компонент Яндекс-карт
            window.setInterval(() => {
                this.getGeolocation();
            }, GEOLOCATION_INTERVAL);
        });
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState == 'visible') {
                this.checkIncompletedOrders();
            }
        });
        document.addEventListener('pushemulator', (e) => {
            this.handleNotification(e.detail);
        });
    },
    methods: {
        /**
         * Получает геолокацию
         */
        async getGeolocation() {
            const ymaps = window.ymaps;
            const geo = await ymaps.geolocation.get({
                mapStateAutoApply: false,
                autoReverseGeocode: false,
            });
            // console.log(geo)
            this.currentPosition = {
                lat: geo.geoObjects.position[0],
                lon: geo.geoObjects.position[1],
            }
            this.yandexGeolocation = geo;
            if (!this.me) {
                this.me = this.currentPosition;
            }
            if (!this.me.region) {
                const response = await this.api.method('geo', { ...this.currentPosition, provider: 'cities' });
                if (response.result) {
                    this.me = response.result;
                }
            }
            document.dispatchEvent(new CustomEvent(
                'spasmaster.yandex.geolocation', 
                { detail: geo }
            ));
        },
        /**
         * Обработка клика по аватару
         */
        async selectAvatarMenu() {
            if (!this.api.data.user.id) {
                return;
            }
            const tr = true; // При попытке шунтировать true в if выдает ошибку
            if (tr || /Cabinet/.test(this.router.currentRoute.value.path)) { // @todo Уточнить как сделать
                const actionSheet = await actionSheetController.create({
                    // header: 'Albums',
                    // cssClass: 'my-custom-class',
                    buttons: [
                        // {
                        //     text: 'Перейти в личный кабинет',
                        //     handler: () => {
                        //         this.router.push('/Cabinet');
                        //     },
                        // },
                        {
                            text: 'Выбрать из галереи',
                            handler: async () => {
                                if (isPlatform('cordova')) {
                                    try {
                                        const results = await ImagePicker.getPictures({
                                            maximumImagesCount: 1,
                                            outputType: window.imagePicker.OutputType.BASE64_STRING,
                                        });
                                        if ((results instanceof Array) && (results.length >= 1) && results[0]) {
                                            this.newAvatar = 'data:image/jpeg;base64,' + results[0];
                                            this.avatarEditorModalOpened = true;
                                        }
                                    } catch (e) {
                                        console.log('fallback to input: ' + e);
                                        this.$refs.avatarInput.click();
                                    }
                                } else {
                                    console.log('no cordova - fallback to input');
                                    this.$refs.avatarInput.click();
                                }
                            },
                        },
                        {
                            text: 'Сделать снимок',
                            handler: async () => {
                                try {
                                    const cameraResult = await Camera.getPicture({
                                        quality: 100,
                                        // allowEdit: true,
                                        targetWidth: 600,
                                        destinationType: Camera.DestinationType.DATA_URL,
                                        encodingType: Camera.EncodingType.JPEG,
                                        mediaType: Camera.MediaType.PICTURE
                                    });
                                    if (cameraResult) {
                                        this.newAvatar = 'data:image/jpeg;base64,' + cameraResult;
                                        this.avatarEditorModalOpened = true;
                                    }
                                } catch (e) {
                                    // this.api.errorHandler('Невозможно сделать фото: ' + e);
                                }
                            },
                        },
                        {
                            text: 'Удалить',
                            role: 'destructive',
                            cssClass: 'action-sheet-red-button',
                            handler: () => {
                                this.onDeleteAvatar();
                            },
                        },
                        {
                            text: 'Отмена',
                            cssClass: 'action-sheet-red-button',
                            role: 'cancel',
                        },
                    ],
                });
                await actionSheet.present();
            } else {
                // this.router.push('/Cabinet')
            }
        },
        /**
         * Запрос на удаление аватара
         */
        async onDeleteAvatar() {
            const alert = await alertController.create({
                cssClass: 'my-custom-class',
                header: 'Удалить аватар?',
                message: 'Вы действительно хотите удалить изображение?',
                buttons: [
                    {
                        text: 'Отмена',
                        role: 'cancel',
                        cssClass: 'secondary',
                        // handler: blah => {
                        //       console.log('Confirm Cancel:', blah)
                        // },
                    },
                    {
                        text: 'Удалить',
                        role: 'destructive',
                        cssClass: 'action-sheet-red-button',
                        handler: async () => {
                            const response = await this.api.method('user/set_avatar', { 
                                'avatar': '',
                            });
                            this.api.updateData(response);
                        },
                    },
                ],
            });
            alert.present();
        },
        /**
         * Выбор аватара из файла
         * @return {[type]} [description]
         */
        async onAvatarSelected(e) {
            const tgt = e.target || window.event.srcElement;

            // FileReader support
            if (tgt.files && tgt.files.length) {
                const file = tgt.files[0];
                if (['image/jpeg', 'image/png', 'image/gif'].indexOf(file.type) != -1) {
                    const result = await new Promise((resolve) => {
                        const reader  = new FileReader();
                        reader.onloadend = () => {
                            resolve(reader.result);
                        }
                        reader.readAsDataURL(file);
                    })
                    this.newAvatar = result;
                    this.avatarEditorModalOpened = true;
                    tgt.value = '';
                } else {
                    this.api.errorHandler('Выбран некорректный файл');
                }
            }
        },

        async checkIncompletedOrders() {
            console.log('Checking incompleted orders...');
            // if (this.incompletedOrders.length || this.api.data.user.executorId) {
                const response = await this.api.method('orders');
                let redirectToOrder = null;
                if (response && response.result) {
                    // for (const incompletedOrder of this.incompletedOrders) {
                    //     if (response.result[incompletedOrder.id + ''] && (response.result[incompletedOrder.id + ''].status_id != incompletedOrder.status_id)) {
                    //         redirectToOrder = incompletedOrder.id;
                    //         console.log(
                    //             'Redirecting to order#' + redirectToOrder + ': changed status ' + 
                    //             incompletedOrder.status_id + '->' + response.result[incompletedOrder.id + '']
                    //         );
                    //         break;
                    //     }
                    // }
                    for (const resultOrder of Object.values(response.result)) {
                        if (
                            (
                                (!this.api.data.user.orders[resultOrder.id + '']) || 
                                (resultOrder.status_id != this.api.data.user.orders[resultOrder.id + ''].status_id)
                            )/* && (resultOrder.type == 'incoming')*/
                        ) {
                            redirectToOrder = resultOrder.id;
                            console.log(
                                'Redirecting to order#' + redirectToOrder + ': changed status ' + 
                                (this.api.data.user.orders[resultOrder.id + ''] && this.api.data.user.orders[resultOrder.id + ''].status_id) + 
                                '->' + resultOrder.status_id
                            );
                            break;
                        }
                    }
                    this.api.updateOrders(response.result, true);
                    if (this.checkInstantOrders()) {
                        return;
                    }
                    if (redirectToOrder) {
                        const rx = new RegExp('OrderStatus\\/' + redirectToOrder, 'gi');
                        if (!rx.test(this.router.currentRoute.value.path)) {
                            this.router.push('/OrderStatus/' + redirectToOrder);
                        }
                    }
                }
            // }
        },

        /**
         * Проверяет наличие немедленного заказа и редиректит на него
         * @return {Number|null} ID# заказа, если есть
         */
        checkInstantOrders()
        {
            if (this.api.data.user && this.api.data.user.orders) {
                const instantOrders = Object.values(this.api.data.user.orders).filter(x => x.type == 'instant');
                if (instantOrders.length) {
                    const redirectToOrder = instantOrders[0].id;
                    console.log('Redirecting to order#' + redirectToOrder + ': instant order');
                    this.router.push('/OrderStatus/' + redirectToOrder);
                    return redirectToOrder;
                }
            }
            return null;
        },

        /**
         * Проверяет состояние подписки на push-уведомления
         */
        async checkPushSubscription() {
            console.log('Push: ', pushNotifications);
            if (this.api.data.user.id) {
                let token = await pushNotifications.register(notification => {
                    // console.log('Push received: ', notification, this);
                    this.handleNotification(notification);
                });
                if (!this.api.data.user.firebase_token || 
                    !this.api.data.user.firebase_token.token || 
                    (this.api.data.user.firebase_token.token != token.token)
                ) {
                    const response = await this.api.method('user/set_firebase_token', { 
                        'token': token,
                    });
                    this.api.updateData(response);
                }
            }
        },
        /**
         * Обрабатывает уведомление
         * @param  {Object} notification Уведомление
         */
        handleNotification(notification) {
            // console.log('Handling notification:', notification);
            if (notification.data && notification.data.performActions) {
                const performActions = notification.data.performActions.split(',');
                // console.log('Has to perform actions:', performActions);
                if (performActions.indexOf('checkIncompletedOrders') != -1) {
                    // console.log('Has to check incompleted orders');
                    this.checkIncompletedOrders();
                }
            }
        },
    },
    computed: {
        /**
         * Незавершенные заявки
         * @return {Object[]}
         */
        incompletedOrders() {
            if (!this.api.data || !this.api.data.user || !this.api.data.user.orders) {
                return [];
            }
            const orders = Object.values(this.api.data.user.orders);
            const incompletedOrders = orders.filter((x) => {
                return (x.type != 'instant') && ['completed', 'cancelled'].indexOf(x.status_id) == -1;
            });
            return incompletedOrders;
        },
        /**
         * Пункты меню
         * @return {Array}
         */
        menuItems() {
            const result = [
                {
                    title: 'Главная',
                    url: '/',
                    icon: home,
                },
            ];
            if (this.api.data.user.id) {
                result.push({
                    title: 'История заказов',
                    url: '/OrderHistory',
                    icon: time,
                    counter: this.incompletedOrders.length || null,
                });
                if (!this.api.data.user.executorId) {
                    result.push({
                        title: 'Мои мастера',
                        url: '/MyExecutors',
                        icon: people,
                    });
                }
                result.push({
                    title: 'Способы оплаты',
                    url: '/PaymentTypes',
                    icon: wallet,
                });
                result.push({
                    title: 'Мои автомобили',
                    url: '/Cars',
                    icon: carSport,
                });
            }
            result.push({
                title: 'Новости',
                url: '/News',
                icon: newspaper,
            });

            if (this.api.data.user.id) {
                let becomePartnerTitle = 'Стать партнером';
                if (this.api.data.user.is_partner) {
                    becomePartnerTitle = 'Анкета партнера';
                }
                result.push({
                    title: becomePartnerTitle,
                    url: '/BecomePartner',
                    icon: briefcase,
                });
            }
            if (this.api.data.infos.Contacts) {
                result.push({
                    title: this.api.data.infos.Contacts.name || 'Контакты',
                    url: '/Info/Contacts',
                    icon: earth,
                });
            }
            if (this.api.data.infos.About) {
                result.push({
                    title: this.api.data.infos.About.name || 'О сервисе',
                    url: '/Info/About',
                    icon: informationCircle,
                });
            }
            result.push({
                title: 'Позвоните нам',
                url: 'tel:%2B7' + this.api.data.rootPhone,
                external: true,
                icon: call,
            });
            return result;
        },
    },
    watch: {
        'api.data.user.id': async function (newVal, oldVal) {
            const response = await this.api.method();
            this.api.updateData(response);
            if (!oldVal && newVal) {
                this.checkPushSubscription();
            }
            if (newVal) {
                this.checkInstantOrders();
            }
        },
    }
});
</script>